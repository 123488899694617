<ng-select [items]="labelList"
           bindLabel="label"
           bindValue="{{valueColumnName}}"
           [(ngModel)]="selectedCountryCode"
           (ngModelChange)="onSelectCountry()"
           [readonly]="readonly"
           style="font-size: 13px;"
           [clearable]="false">

    <ng-template ng-label-tmp let-item="item">
        <img src="{{item?.image}}" class="flags" *ngIf="item?.image" alt="">{{item?.label}}
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-index="index"
                 let-search="searchTerm">
        <img src="{{item?.image}}" class="flags" *ngIf="item?.image" alt="">{{item?.label}}
    </ng-template>
</ng-select>
