import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class FileUploaderService {

    constructor(private http: HttpClient) {
    }

    removeFile(attachments: any[]) {
        return this.http.post('customer/deleteCustomerOrderAttachment', attachments);
    }
}
