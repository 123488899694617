export const DATA_TABLE_CONFIG = {
    cssClasses: {
        sortAscending: 'datatable-icon-down',
        sortDescending: 'datatable-icon-up',
        pagerLeftArrow: 'fa fa-caret-left',
        pagerRightArrow: 'fa fa-caret-right',
        pagerPrevious: 'fa fa-backward',
        pagerNext: 'fa fa-forward'
    },
    headerHeight: 50,
    footerHeight: 50,
    rowHeight: 'auto'
};
