import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {NavService} from '../../services/nav.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    public items: any[];
    public openNav = false;
    public rightSidebar = false;
    public text: string;

    @Output() rightSidebarEvent = new EventEmitter<boolean>();

    constructor(
        public navServices: NavService
    ) {
    }

    ngOnInit() {
        this.navServices.items.subscribe(menuItems => {
            this.items = menuItems;
        });
    }

    collapseSidebar() {
        this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
    }

    onLogout() {
        // this.authService.logout();
    }

}
