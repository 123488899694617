import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiResponse, IPaginate} from '../../models/api-response.model';


@Injectable({
    providedIn: 'root'
})
export class TableApiService {

    constructor(private http: HttpClient) {
    }

    getPagination(url: string, params) {
        return this.http.get<ApiResponse<IPaginate>>(
            `/${url}`, {params}
        );
    }
}
