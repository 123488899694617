<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">Table goes here</div>
      </div>
    </div>
  </div>
</div>
