export const ValidAttachmentTypes = '.jpg,.png,.pdf,.jpeg ,.bmp,.doc,.docx,.csv,.xlsx,.xls';
export const ValidImageTypes = '.jpg,.png,.jpeg';
export const MaximumFileSize = 5 * 1024 * 1024;
export const MaximumFileLabel = '5MB';
export const attachmentDownloadTypes = {
    taskAttachment: 'TASK_ATTACHMENT',
    taskLocationAttachment: 'TASK_LOCATION_ATTACHMENT',
    userAttachments: 'USER_ATTACHMENTS',
    recurringTemplateAttachment: 'RECURRING_TEMPLATE_ATTACHMENT',
    recurringAttachment: 'RECURRING_ATTACHMENT',
    leaveAttachments: 'USER_LEAVE_ATTACHMENT',
    postAttachments: 'POST_ATTACHMENT',
    customerAttachments: 'CUSTOMER_MASTER_ATTACHMENT',
    customerOrderAttachments: 'CUSTOMER_ORDER_ATTACHMENT'
};
