import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-attachment-icon',
    templateUrl: './attachment-icon.component.html',
    styleUrls: ['./attachment-icon.component.scss']
})
export class AttachmentIconComponent implements OnInit {
    @Input() url: string;

    constructor() {
    }

    ngOnInit(): void {
    }

}
