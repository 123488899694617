<div class="modal-body">
    <div class="card signup mb-0">
        <div class="card-body">
            <form>
                <div class="row">
                    <div class="col-12">
                        <div class="p-5 text-center">
                            <img src="/assets/images/promate-life-img/payment.gif" alt="payment-img"
                                 class="payment-img img-fluid">

                            <h4 class="title-text text-center mb-4">Your Order is Successful</h4>

                            <div class="row">
                                <div class="col-12">
                                    <p> Thank you for your order. </p>
                                </div>
                            </div>

                            <div class="row mt-3">
                                <div class="col-12 text-center">
                                    <button type="button" class="btn-pill btn-yellow" [routerLink]="'/'"
                                            (click)="hideModal()">
                                        Got it
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
