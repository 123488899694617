import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {HeaderComponent} from './components/header/header.component';
import {FooterComponent} from './components/footer/footer.component';
import {SidebarComponent} from './components/sidebar/sidebar.component';
import {ContentLayoutComponent} from './components/layout/content-layout/content-layout.component';
import {FeatherIconsComponent} from './components/feather-icons/feather-icons.component';
import {BreadcrumbComponent} from './components/breadcrumb/breadcrumb.component';
import {ToggleFullscreenDirective} from './directives/fullscreen.directive';
import {Ng2LoadingSpinnerModule} from 'ng2-loading-spinner';
import {ToastrModule} from 'ngx-toastr';
import {TableComponent} from './components/table/table/table.component';
import {TableWrapperComponent} from './components/table/table-wrapper/table-wrapper.component';
import {NavService} from './services/nav.service';
import {BsModalRef, BsModalService, ModalModule} from 'ngx-bootstrap/modal';
import {EmailVerificationPopupComponent} from './components/email-verification-popup/email-verification-popup.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {CountryCodePickerComponent} from './components/country-code-picker/country-code-picker.component';
import { PaymentConfirmationPopupComponent } from './components/payment-confirmation-popup/payment-confirmation-popup.component';
import {GoogleMapsModule} from '@angular/google-maps';
import {AgmCoreModule} from '@agm/core';
import {GoogleLocationComponent} from './components/google-location/google-location.component';
import { LocationPickerComponent } from './components/location-picker/location-picker.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import {FileUploadModule} from 'ng2-file-upload';
import {AttachmentIconComponent} from './components/attachment-icon/attachment-icon.component';
import {AttachmentViewerComponent} from './components/attachment-viewer/attachment-viewer.component';


@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        SidebarComponent,
        ContentLayoutComponent,
        FeatherIconsComponent,
        BreadcrumbComponent,
        ToggleFullscreenDirective,
        TableComponent,
        TableWrapperComponent,
        CountryCodePickerComponent,
        EmailVerificationPopupComponent,
        PaymentConfirmationPopupComponent,
        GoogleLocationComponent,
        LocationPickerComponent,
        FileUploaderComponent,
        AttachmentIconComponent,
        AttachmentViewerComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        Ng2LoadingSpinnerModule.forRoot({}),
        ToastrModule.forRoot({}),
        NgxDatatableModule,
        NgSelectModule,
        GoogleMapsModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyCqcNfeISzX0I8ePdREz8FGpAdjXg2e9TE',
            libraries: ['places']
        }),
        ReactiveFormsModule,
        FileUploadModule,
        ModalModule
    ],
    exports: [
        FeatherIconsComponent,
        Ng2LoadingSpinnerModule,
        ToastrModule,
        TableComponent,
        TableWrapperComponent,
        NgxDatatableModule,
        CountryCodePickerComponent,
        EmailVerificationPopupComponent,
        PaymentConfirmationPopupComponent,
        GoogleLocationComponent,
        FileUploaderComponent,
        AttachmentViewerComponent
    ],
    providers: [
        NavService,
        BsModalService,
        BsModalRef,
    ]
})
export class SharedModule {
}

