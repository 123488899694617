<div class="container-fluid">
    <div class="page-header">
        <div class="row">
            <div class="col-lg-6">
                <div class="page-header-left">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"
                            *ngIf="breadcrumbs?.parentBreadcrumb">{{breadcrumbs?.parentBreadcrumb}}</li>
                        <li class="breadcrumb-item active"
                            *ngIf="breadcrumbs?.childBreadcrumb">{{breadcrumbs?.childBreadcrumb}}
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
</div>
