import {Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild} from '@angular/core';
import {MapsAPILoader} from '@agm/core';

@Component({
    selector: 'app-google-location',
    templateUrl: './google-location.component.html',
    styleUrls: ['./google-location.component.scss']
})
export class GoogleLocationComponent implements OnInit {
    @Output() onLocationPick: EventEmitter<{
        latitude: any,
        longitude: any
    }> = new EventEmitter<any>();
    @Output() onLocationName: EventEmitter<any> = new EventEmitter<any>();

    @Input() latitude: number;
    @Input() longitude: number;
    zoom = 4;

    @ViewChild('search')
    public searchElementRef: ElementRef;


    constructor(private mapsAPILoader: MapsAPILoader,
                private ngZone: NgZone) {
    }

    ngOnInit() {
        this.mapsAPILoader.load().then(() => {
            const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
            autocomplete.addListener('place_changed', () => {
                this.ngZone.run(() => {
                    const place: google.maps.places.PlaceResult = autocomplete.getPlace();

                    if (place.geometry === undefined || place.geometry === null) {
                        return;
                    }

                    this.latitude = place.geometry.location.lat();
                    this.longitude = place.geometry.location.lng();
                    this.zoom = 12;

                    this.onLocationPick.emit({
                        latitude: this.latitude,
                        longitude: this.longitude
                    });

                    this.onLocationName.emit(place.formatted_address);
                });
            });
        });

    }

    mapClicked(event) {
        this.latitude = event.coords.lat;
        this.longitude = event.coords.lng;
        this.onLocationPick.emit({
            latitude: event.coords.lat,
            longitude: event.coords.lng
        });
    }

}
