import {ANIMATION_TYPES} from 'ng2-loading-spinner';

export const DEFAULT = {
    companyImage: '/assets/images/attachment-3.jpg',
    userImage: '/assets/images/user/user.png',
    btnLoadingConfig: {
        animationType: ANIMATION_TYPES.halfCircle,
        backdropColor: 'rgba(0, 0, 0, 0.3)',
        spinnerColor: '#fff',
        spinnerPosition: 'center',
        backdropBorderRadius: '8px',
        spinnerSize: 'md',
        spinnerFontSize: '0.8rem'
    },
    divLoadingConfig: {
        animationType: ANIMATION_TYPES.halfCircle,
        backdropColor: 'rgba(106,106,106,0.42)',
        spinnerColor: '#fff',
        spinnerPosition: 'center',
        backdropBorderRadius: '8px',
        spinnerSize: 'md',
        spinnerFontSize: '1.5rem'
    },
    ACTION_ITEM_STATUS: [{id: 'OPEN', name: 'OPEN'}, {id: 'CLOSED', name: 'CLOSED'}],
    AUDIT_FINDING_STATUS: [
        {id: 'DRAFT', name: 'DRAFT'},
        {id: 'SUBMITTED', name: 'SUBMITTED'},
        {id: 'APPROVED', name: 'APPROVED'},
        {id: 'REJECTED', name: 'REJECTED'},
        {id: 'SENT_TO_CLIENT', name: 'SENT_TO_CLIENT'},
        {id: 'COMPLETED', name: 'COMPLETED'}
    ]
};
