<div *ngIf="url" class="">
    <ng-container *ngIf="url.includes('.pdf')">
        <a [href]="url" target="_blank">
            <span>Pdf <img class="icon" src="assets/images/attachments/pdf.png" alt=""></span>
        </a>
    </ng-container>
    <ng-container
            *ngIf="url.includes('.png') || url.includes('.jpg') || url.includes('.jpeg') || url.includes('.bmp') || url.includes('.png')">
        <a [href]="url" target="_blank">
            <span>Image <img class="icon" src="assets/images/attachments/image.png" alt=""></span>
        </a>
    </ng-container>
    <ng-container
            *ngIf="url.includes('.doc') || url.includes('.docx') || url.includes('.csv') || url.includes('.xlsx') || url.includes('.xls')">
        <a [href]="url" target="_blank">
              <span>File <img class="icon" src="assets/images/attachments/file.png" alt=""></span>
        </a>
    </ng-container>
</div>
