<div class="modal-header">
    <h4 class="modal-title pull-left">Your Location</h4>
    <button type="button" class="close modal-close" aria-label="Close" (click)="hideModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="card signup mb-0">
        <div class="card-body">
            <form>
                <div class="row">
                    <div class="col-12">
                        <div class="p-2 text-center">

                            <app-google-location (onLocationPick)="onLocationPick($event)"
                                                 (onLocationName)="onLocationName($event)"></app-google-location>

                            <div class="row mt-3 mb-1">
                                <div class="col-12 text-center">
                                    <button type="button" class="btn-pill btn-yellow" (click)="confirmLocation()">
                                        Confirm
                                    </button>
                                </div>
                            </div>

                            <p class="mb-1">Hi There! Set your location and explore the best professionals in your
                                area</p>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
