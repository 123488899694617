<button type="button" class="close modal-close" aria-label="Close" (click)="hideModal()">
    <span aria-hidden="true">&times;</span>
</button>

<div class="modal-body">
    <div class="card signup mb-0">
        <div class="card-body">
            <div class="row">
                <div class="col-12">
                    <div class="p-5 text-center">
                        <img src="/assets/images/promate-life-img/email.gif" alt="verifymail-img"
                             class="verify-mail img-fluid">

                        <h4 class="title-text text-center mb-4">Verify your email address</h4>

                        <div class="row">
                            <div class="col-12">
                                <p>You've entered {{email}} as the email address for your account.
                                    <br>Please verify this email.
                                </p>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-12 text-center">
                                <button type="submit" class="btn-pill btn-blue" (click)="hideModal()">
                                    Got it
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
