import {AfterViewInit, Component, OnInit} from '@angular/core';
import {NavService} from '../../../services/nav.service';
import * as feather from 'feather-icons';
import {CustomizerService} from '../../../services/customizer.service';
import {HttpClient} from '@angular/common/http';

@Component({
    selector: 'app-content-layout',
    templateUrl: './content-layout.component.html',
    styleUrls: ['./content-layout.component.scss'],
})
export class ContentLayoutComponent implements OnInit, AfterViewInit {

    constructor(
        public navServices: NavService,
        public customizer: CustomizerService,
        public http: HttpClient
    ) {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        setTimeout(() => {
            feather.replace();
        });
    }


}
