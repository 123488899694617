import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';


@Component({
    selector: 'app-payment-confirmation-popup',
    templateUrl: './payment-confirmation-popup.component.html',
    styleUrls: ['./payment-confirmation-popup.component.scss']
})
export class PaymentConfirmationPopupComponent implements OnInit {

    constructor(public bsModalRef: BsModalRef) {
    }

    ngOnInit(): void {
    }

    hideModal(): void {
        this.bsModalRef.hide();
    }
}
