import {Component, OnDestroy, OnInit} from '@angular/core';
import {fromEvent, merge, Observable, Observer, Subscription} from 'rxjs';
import {map} from 'rxjs/internal/operators';
import {Router} from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'Help Desk System';
    offlineSubscription: Subscription;

    constructor(private router: Router) {
    }

    ngOnInit(): void {
        this.offlineSubscription = this.createOnline$().subscribe(isOnline => {
            if (!isOnline) {
                this.router.navigate(['no-network']);
            }

        });
    }

    createOnline$() {
        return merge<boolean>(
            fromEvent(window, 'offline').pipe(map(() => false)),
            fromEvent(window, 'online').pipe(map(() => true)),
            new Observable((sub: Observer<boolean>) => {
                sub.next(navigator.onLine);
                sub.complete();
            }));
    }

    ngOnDestroy(): void {
        this.offlineSubscription.unsubscribe();
    }

}
