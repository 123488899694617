import {HostListener, Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';


@Injectable({
    providedIn: 'root'
})

export class NavService {

    public screenWidth: any;
    public collapseSidebar = false;
    items = new BehaviorSubject<any[]>([]);

    constructor() {
        this.onResize();
        if (this.screenWidth < 991) {
            this.collapseSidebar = true;
        }

        this.getNav();
    }


    setNav(navs) {
        this.items.next(navs);
        localStorage.setItem('navs', JSON.stringify(navs));
    }

    // Windows width
    @HostListener('window:resize', ['$event'])
    onResize() {
        this.screenWidth = window.innerWidth;
    }

    destroyNavs() {
        this.items.next([]);
        localStorage.removeItem('navs');
    }

    private getNav(): void {
        this.items.next(JSON.parse(localStorage.getItem('navs')));
    }
}
