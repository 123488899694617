import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-email-verification-popup',
    templateUrl: './email-verification-popup.component.html',
    styleUrls: ['./email-verification-popup.component.scss']
})
export class EmailVerificationPopupComponent implements OnInit {
    email: string;

    constructor(public bsModalRef: BsModalRef) {
    }

    ngOnInit(): void {
    }

    hideModal(): void {
        this.bsModalRef.hide();
    }
}
