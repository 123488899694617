import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-location-picker',
    templateUrl: './location-picker.component.html',
    styleUrls: ['./location-picker.component.scss']
})
export class LocationPickerComponent implements OnInit {
    location: any = {
        lat: null,
        lng: null
    };
    locationName: string;

    constructor(public bsModalRef: BsModalRef) {
    }

    ngOnInit(): void {
    }

    hideModal(): void {
        this.bsModalRef.hide();
    }

    onLocationPick(location: { latitude: any, longitude: any }): void {
        this.location.lng = location.longitude;
        this.location.lat = location.latitude;
    }

    onLocationName(locationName): void {
        this.locationName = locationName;
    }


    confirmLocation() {
        if (this.location.lng && this.location.lat) {
            localStorage.setItem('location', JSON.stringify(this.location));
            localStorage.setItem('location_name', JSON.stringify(this.locationName));
            this.bsModalRef.hide();
            window.location.reload();
        } else {
            this.bsModalRef.hide();
        }

    }


}
