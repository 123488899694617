import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CountryCode} from '../../models/customer-dropdown.model';

@Component({
    selector: 'app-country-code-picker',
    templateUrl: './country-code-picker.component.html',
    styleUrls: ['./country-code-picker.component.scss']
})
export class CountryCodePickerComponent implements OnInit {
    @Input() countryCodeList: CountryCode[] = [];
    @Input() selectedCountryCode: any;
    @Input() valueColumnName = 'value';
    @Output() onSelectCountryId: EventEmitter<any> = new EventEmitter<any>();
    labelList: any = [];
    @Input() readonly = false;

    constructor() {
    }

    ngOnInit(): void {
        if (this.countryCodeList?.length > 0) {
            this.countryCodeList.forEach(country => {
                this.labelList.push({
                    label: '+' + country.phonecode,
                    value: country.id,
                    iso: country.iso,
                    image: country.iso ? 'assets/flags/' + country.iso.toLowerCase() + '.svg' : null
                });
            });
        }
    }

    onSelectCountry() {
        this.onSelectCountryId.emit(this.selectedCountryCode);
    }
}
